<template>

        <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" :style="{ 'background-color': bgColor }" data-fab-toggle="hover">
            <li>
                <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon " @click="$emit('click', $event.target.click)">
                    <i class="fab-icon-open icon-plus3"></i>
                    <i class="fab-icon-close icon-plus3"></i>
                </a>

                <!--<ul class="fab-menu-inner">-->
                    <!--<li>-->
                        <!--<div data-fab-label="New">-->
                            <!--<a href="#" class="btn btn-light rounded-round btn-icon btn-float bg-danger"    @click="$emit('click', $event.target.click)" >-->
                                <!--<i class="icon-pencil"></i>-->
                            <!--</a>-->
                        <!--</div>-->
                    <!--</li>-->
                <!--</ul>-->

            </li>
        </ul>


</template>

<script>

    export default {
        name: "FabButton",
        data(){
            return {
                open_icon: null
            }
        },
        props: {
            bgColor: {
                default: 'bg-info',
            },
            position: {
                default: 'fab-menu-bottom-right',
            },
        },
        created () {
            console.log('created : ' + (new Date()).toLocaleTimeString() );
            this.open_icon = 'icon-plus3';
        },
        events: {
            onClick: function () {
                alert('On Click');
            }
        },
        methods:{
            emitNewClickEvent() {
                this.$broadcast('clickOnNew');
                this.el$.emitNewClickEvent();
            }
        }
    }
</script>

<style scoped>

</style>
